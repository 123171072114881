
@media screen and (min-width: 800px) {
    .videowid {
        margin-top: 3%;
        height: 500px;
      
        background-size: cover;
    
    }
}

.VIR{
    display: flex;
     flex-direction: column;
     border: 2px solid black;
}