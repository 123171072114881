#form {
      background-color: #333;
      width: 80%;
      margin-left: 10%;
      padding: 3%;
      border-radius: 2%;
      box-shadow: 0 0 1% rgba(0, 0, 0, 0.2);
      padding-top: 2%;
      margin-bottom: 3%;
      transition: box-shadow 0.3s ease-in-out;
}

#form:hover {
      box-shadow: 0 0 2% rgba(0, 0, 0, 0.4);
}

.labeltext {
      font-weight: 700;
      font-size: 1.1em;
      margin-bottom: 1%;
      color: rgb(255, 255, 255);
}

.form-group-1 {
      margin-bottom: 3%;
}

.form-control {
      width: 96%;
      border: 1% solid #ccc;
      border-radius: 2%;
      padding: 2%;
      box-sizing: border-box;
      font-weight: 700;
      font-size: 1em;
      transition: border-color 0.3s ease-in-out;
}

.form-control:focus {
      border-color: #6cb2eb;
}

.btn-primary {
      color: white;
      background-color: black;
      padding:20%;
      margin-top: 2%;
      align-items: center;

      font-weight: 800;
      cursor: pointer;
    
      transition: background-color 0.3s ease-in-out;
}

.btn-primary-1 {
      color: white;
      background-color: black;
      padding:2%;
      margin-top: 2%;
      align-items: center;

      font-weight: 800;
      cursor: pointer;

      transition: background-color 0.3s ease-in-out;
}
.btn-primary:hover {
      background-color: #525252;
}

select {
      width: 100%;
      padding: 2%;
      border: 1% solid #ccc;
      border-radius: 2%;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 1em;
      transition: border-color 0.3s ease-in-out;
}

select:focus {
      border-color: #6cb2eb;
}

/* Placeholder styles */
#posttitle::placeholder,
#content::placeholder,
#nichetype::placeholder {
      font-family: cursive;
      color: #888;
}

/* Optional: Style for Paper component */
.Paper {
      padding: 2%;
      margin: 5% auto;
      border-radius: 2%;
      background-color: #f5f5f5;
      box-shadow: 0 0 1.5% rgba(0, 0, 0, 0.3);
      transition: box-shadow 0.3s ease-in-out;
}

.Paper:hover {
      box-shadow: 0 0 2% rgba(0, 0, 0, 0.4);
}


#modal-modal-description{
      font-weight: 700;
}

.form-group-2 {
        display: flex;
        flex-wrap: wrap;
}

.upbtn{
   
      width:auto;
      height:auto;
      margin-top: 3%;
      align-items: center;
      align-content: center;
}