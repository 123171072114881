.postbinder,.little{
   background-color:  rgb(237, 237, 252);
}

#userimage{
     width:95%;
     height:40%;
      border-radius: 50%;
}
.signupimage {
   margin-left: 15%;
   text-decoration: none;

   border: 1px solid black;
   color: white;
   background-color: rgb(163, 231, 242);
   font-weight: 620;
   border-radius: 5%;
 
   cursor: pointer;
   transition: background-color 0.3s ease;
}
.channelicon{
   width:9%;
    margin-right:0%;
}


.imagefor{
  
      height: 200px;
} 


@media screen and (max-width:500px){
   .imagefor {
   
         height: 110px;
      }
}

#papercl{
   width: 100%;
   margin: 0%;
   padding: 0%;
}


@media screen and (max-width:500px){
   #papercl {
         width: 75%;
         margin: 0%;
         padding: 0%;
      }
}