.universe {
   width:450px;
    margin-top:2%;
    padding-left:1%;
    margin-left:1%;
    
}
@media screen and (max-width:450px){
    .universe {
            width: 100%;
            margin-top: 2%;
            padding-left: 1%;
            margin-left: 1%;
    
        }
}

.universe:hover{
    width: 440px;
    display: grid;
    margin-right: 1.3%;
    margin-top: 2%;
    padding-left: 1%;
    transform: rotate(0.18deg);
}
@media screen and (max-width:450px) {
    .universe:hover {
            width: 100%;
            display: grid;
            margin-right: 1.3%;
            margin-top: 2%;
            padding-left: 1%;
            transform: rotate(0.18deg);
        }
}

.space {
    background-color: rgb(218, 243, 243);
    margin: 1%;
    padding-top: 1%;
    padding-left: 1%;
    padding-bottom: 1%;
    padding-right: 1%;
    display: grid;
}

.headingglance{
    
   margin-top:1%;
   margin-left:1%;
   margin-right:1%;
   margin-bottom:1%;
    width:95%;
    height:87%;
    padding-left:2%;
    
}

.headingtext {
    font-size: 1.24vw;
    color: rgb(10,10, 10); 
    font-weight: 900;
   font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
@media only screen and (max-width:700px) {
    .headingtext {
        font-size: 3.5vw;
      
        font-weight: bolder;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
        

}

@media only screen and (max-width:400px) {
    .headingtext {
        font-size: 8vw;
      
        font-weight: bolder;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }


}

.glancecontent{
         display:flex;
        
         margin-left:1%;
         margin-right:1%;
         
}
.glanceimage{
      width:50%;
      height:50%;
      overflow: hidden;
 
      margin-top:1%;
      margin-left:1%;
      padding:0.5%;
      
}

.glancebriefbox{
      
       margin:1%;
       padding:1%;

    }
    .glancebrieftext{
        font-size:0.89vw;
        font-weight:600;
        color:rgb(51, 25, 0);
        font-family:Georgia, 'Times New Roman', Times, serif;
    }
        @media only screen and (max-width:700px) {
            .glancebrieftext {
                font-size: 3.0vw;
                font-style: bold;
                font-weight: bolder;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            }
    
    
        }
    

        @media only screen and (max-width:400px) {
            .glancebrieftext {
                font-size: 5.0vw;
                font-style: bold;
                font-weight: bolder;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            }
    
    
        }
    .little{
        height:20%;
        margin:1%;
        display:flex;
        background-color:rgb(218, 243,243);
       
    }
    .main1{
          display:flex;
    }



        @media only screen and (max-width:1200px) {
            .main1 {
                    display: grid;
                }
        }
    

.like{
     padding-left:40%;
    color: #333;
    cursor: pointer;
}
.comment{
    padding-left:3.4%;
    color: #333;
    cursor: pointer;
}
.author{
        color:#333;
        display:flex;
         align-items: center;
       
}
.profile{
     padding-top: 2%;
     margin-left: 5%;
     text-transform: capitalize;
}

.remove{
    text-decoration: none;
    color: rgb(19, 19, 19);
}

.userchannelimage {
    height: 50%;
    width: 80%;
    border-radius: 50%;
}
.imagefitting{
    height:50%;
    width:100%;
}

.universe1{
    background-color: aqua;
}