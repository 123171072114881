.videotem{
    margin: 2%;
     display:grid;
     grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
}

.videoto{
    padding-left: 8%;
    margin-right: 1%;
    height: 100%;
    width: 89%;
    margin-top: 6%;
}

.videomk{
    display: flex;
    flex-direction: column;
}

.videotitle{
    font-size: 1.5vw;
    font-weight: 900;
    margin-left: 6%;

}