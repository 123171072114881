.papersClass {

    width: 70%;

    height: 100%;

    padding: 2px;

    margin: 1px;

}
.maincontent{
    margin-left: 1%;
}