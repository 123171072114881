.contenttitle{
    font-size: 2.3vw;
    font-weight: 800;
    text-transform: capitalize;
    color:rgb(47, 42, 42);
    margin-left: 1%;
}
@media screen and (max-width:450px){
    .contenttitle {
            font-size: 4.7vw;
            font-weight: 800;
            text-transform: capitalize;
            color: rgb(47, 42, 42);
            margin-left: 1%;
        }
}
.contentuserimage{
      height: 3%;
      width:5%;
      border-radius: 50%;
      margin-top: 3%;
      margin-left: 2%;
}
@media screen and (max-width:450px) {
.contentuserimage {
        height: 6%;
        width: 8%;
        border-radius: 50%;
        margin-top: 3%;
        margin-left: 2%;
    }
}

.contentprofile {
    display: flex;
   
    align-items: center;
    /* Vertically center content */
}

.contentusername{
    padding-top: 2%;
    font-weight: 600;
    font-size: 1.1vw;
    margin-left:1%;
}


@media screen and (max-width:580px){
    .contentusername {
            padding-top: 2.2%;
            font-weight: 600;
            font-size: 2vw;
            margin-left: 1%;
        }
}

.contentcreatedAt{
    margin-top: 2%;
}

.contentmaincontent::first-letter {
    text-transform: uppercase;

}
.contentmaincontent{
    margin-top:2%;
    margin-left: 25%;
    margin-right: 22%;
    font-weight: 600;
    color: rgb(52, 52, 52);
    word-spacing: 1vw;
}


.contentpageimage{
    width:50%; 
     height:350px;
}


.contentpageimagebox{
    margin-left: 25%;
    margin-top: 3%;
}



.summarywatched{
        margin-left: 25%;
        
}

@media screen and (max-width:400px) {
    .summarywatched {
            margin-left: 15%;
            height: 8px;
            
        }
}


.contenttextaws{
    font-weight: 600;
    color: rgb(52, 52, 52);

}


.html-content {
    padding: 16px;
    background-color: #f7fafc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}