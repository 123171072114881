.heading {
    background:linear-gradient(0deg,rgb(0,0,0),rgb(50, 49, 49));

    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100px;
    z-index:9000;
    display: flex;
    align-items: center;
     margin-bottom:1%;
    padding: 1% 2%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    display: flex;
    align-items: center;
    color:rgb(178, 177, 177);
}

.logo img {
    width: 100%; 
    height: auto; 
  
}
.logoimage{
    width:12%;
    height:100%;
    overflow: hidden;

}
.image{
    width:auto;
    display: flex;
    align-items: center;
    height: auto;
    margin-left: 17%;
    
}

.apple {
    
    padding-top: 2%; 
    padding-left:1%;
    font-size: 1.5vw;
    color: rgb(178, 177, 177);
    display: grid;
      grid-template-columns: repeat(3,1fr);
}

@media screen and (max-width:700px){
    .apple {
    
            padding-top: 2%;
            padding-left: 1%;
            font-size: 3.5vw;
            color: rgb(0, 0, 0);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
             
}
@media screen and (max-width:400px) {
    .apple {

        padding-top: 2%;
        padding-left: 1%;
        font-size: 6vw;
        color: rgb(0, 0, 0);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

}

.Finglobal{
    font-weight: 700;
    grid-column:1/3;
    grid-row: 1/2;
    font-family:cursive;
}
@media screen and (max-width:700px) {
    
    .Finglobal {
        font-weight: 700;
        grid-column: 1/3;
        grid-row: 1/2;
        color: #f8f6f6;
    }

}
.Finglobal::first-letter {
    font-weight: 900;
    font-size: 2vw;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:rgb(245, 97, 5) ;

}
@media screen and (max-width:700px) {

    .Finglobal::first-letter {
        font-weight: 900;
        font-size: 4vw;

    }

}
@media screen and (max-width:400px) {

    .Finglobal::first-letter {
        font-weight: 900;
        font-size: 7vw;

    }

}
.com{
    font-weight: 700;
    grid-column: 1/3;
    grid-row: 3/4;
    padding-left:30%;
    color: #918c8c;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
@media screen and (max-width:700px) {
    .com {
        font-weight: 700;
        grid-column: 1/3;
        grid-row: 3/4;

    }
}
.menu,
.notification {
    padding: 1%; 
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.menu:hover,
.notification:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.signup {

    
    text-decoration: none;
    padding: 4% 6%;
    border: 1px solid black;
    color: white;
    background-color: rgb(163, 231, 242);
    font-weight:620;
    border-radius: 5%;
    margin-right: 70px; 
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup:hover {
    background-color: #f8f6f6;
    color: black;
}

.inflex {
    margin-left:65%;
    display: flex;
    align-items: center;
    
}

@media screen and (max-width:700px){
    .inflex {
            margin-left: 35%;
            display: flex;
            align-items: center;
    
        }
}

.createremove{
    
    text-decoration: none;
        display: none;

}



#profileimage{
      border-radius:50%;
      height:10%;
      width: 15%;
    
}
@media screen and (max-width:1050px){
    #profileimage {
            border-radius: 50%;
            height: 10%;
            width: 25%;
    
        }
    
}
@media screen and (max-width:500px) {
    #profileimage {
        border-radius: 50%;
        height: 8%;
        width: 25%;

    }

}

.signupimage {
    margin-left: 15%;
    text-decoration: none;
 
    border: 1px solid black;
    color: white;
    background-color: rgb(163, 231, 242);
    font-weight: 620;
    border-radius: 5%;
    margin-right: 2%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

