


.zero-saved-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-content {
    text-align: center;
}

#savedcountmessage {
    font-weight: 600;
    font-size: 1.2rem;
}