.paperClass {
    
    width: 70%;
    
    height: 100%;
    
    padding: 2px;
   
    margin: 1px;
    
}
/* lastest.css */

.post-row {
   
   width: 470px;
   margin-bottom:15%;
} 
@media screen and (max-width:1000px) {
    .post-row {
       
        width: 550px;
        margin-bottom: 10%;
    }
}
@media screen and (max-width:690px){
    .post-row {
            
            width: 550px;
            margin-bottom:5%;
            margin-left:1%;
        }
    }

        @media screen and (max-width:450px) {
            .post-row {
    
                width:100%;
                margin-bottom: 5%;
            
            }
        }

.postcard {
  
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    
}



.maincontent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      
    margin-top: 2%;
    margin-left: 5%;
}