
.hu{
  color: #000;
}

.main{
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 3%;
 }  




 
.appheading {
 
grid-column: 1/ 3;
  grid-row: 1;
}


.approuting {
  grid-column: 1/ 4;
  grid-row: 2/5;
}

.appleft{
  grid-column: 1 / 2;
  grid-row:2/5;
  
    
}


#root{
  margin: 0;
    padding: 0;
    box-sizing: border-box;
}