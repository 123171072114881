.userimagesubscriber{
    width:40%;
    height:60%;
    border-radius: 50%;
}
.usersubscriberbox{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 14%;
}


.link{
       text-decoration: none;
            color: rgb(248, 248, 248);
        
}